import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Errors, UserService } from '../core';
import { EmailSearch } from './email-search.service';

import { map ,  take } from 'rxjs/operators';
import { EmailValidationService } from '../core/services/email-validation.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {
  authType: boolean = false;
  title: String = '';
  errors: Errors = {errors: {}};
  isSubmitting = false;
  searchForm: FormGroup;
  results: any[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private emailSearch: EmailSearch,
    private emailValidationService: EmailValidationService
  ) {
    // use FormBuilder to create a form group
    this.searchForm = this.fb.group({
      'email': ['', Validators.required]
    });
  }

  ngOnInit() {
    this.route.url.subscribe(data => {
      // Set a title for the page accordingly
      this.userService.isAuthenticated.pipe(take(1), map(isAuth => !isAuth)).subscribe(data => {this.authType = data});
      this.title = (this.authType) ? 'Validate' : 'Validate';
      this.results = Array();
    });
  }

  submitForm() {
    this.isSubmitting = true;
    this.errors = {errors: {}};

    const email = this.searchForm.value.email;
    this.emailValidationService.get(email).subscribe(
      data => {
        this.results.push(data);
        console.log(this.results);
        this.isSubmitting = false;
        this.searchForm.value.email = "";
      }
    );
    // this.emailSearch
    // .attemptValidate(email)
    // .subscribe(
    //   data => {console.log(data)},
    //   err => {
    //     this.errors = err;
    //     this.isSubmitting = false;
    //   }
    // );
  }
}
