import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AisearchComponent } from './aisearch.component';
import { EmailAisearch } from './email-aisearch.service';

const routes: Routes = [
  {
    path: '',
    component: AisearchComponent,
    canActivate: [EmailAisearch]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AisearchRoutingModule {}
