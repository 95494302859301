import { Component, OnInit } from '@angular/core';
import { UserService, ApiService } from '../core';
import { EmailValidationService } from '../core/services/email-validation.service';
import { EmailSearch } from '../search/email-search.service';
import { Observable , of, pipe, interval, timer} from 'rxjs'; import { map,take, delay } from "rxjs/operators";
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
enum Status {
  valid = "VALID",
    invalid = "INVALID",
    pending="PENDING",
    catchAll ="CATCH_ALL"
}
class EmailViewModel {
	id?: string;
	name?: string;
	email?: string;
	country_code?: string;
	phone?: string;
	status?: string;
	profile_image?: string;
	created_at?: string;
}

@Component({
  selector: 'app-csv-file-upload',
  templateUrl: './csv-file-upload.component.html',
  styleUrls: ['./csv-file-upload.component.css']
})
export class CsvFileUploadComponent implements OnInit {
  private activeRowItem: EmailViewModel = new EmailViewModel();
  private totalCount: number = 0;
  private totalCompletedCount: number= 0;
  // private totalCompletedCount$: Observable = this.totalCompletedCount.asObservable();
  private readonly maxRequestLimit: number = 5;
  private pendingRequestCount :number = 0;

  isSubmitting = false;
	results: any[];
  curr_index: number = 0;
	data = [];
  dataSource: {[key: string]: EmailViewModel}= {}
  parallelCount: number = 20;
	// public dataSource$:Subject<EmailViewModel[]> = new Subject<EmailViewModel[]>();
  constructor(
    private readonly apiService: ApiService,
    private readonly httpClient: HttpClient,
    private readonly userService: UserService,
    private readonly emailSearch: EmailSearch,
    private emailValidationService: EmailValidationService,
    ) {}

  ngOnInit() {
    this.results = Array();
  }
  private isReadyToProcess(): boolean {
    return this.pendingRequestCount < this.maxRequestLimit;
  }

  private requestQueue(){

    if(this.totalCount>0){

      const numbers = interval(1000);
      const queue = numbers.pipe(take(this.totalCount));
      queue.subscribe({

        next: res => {
         if(this.isReadyToProcess()){
            console.log(this.pendingRequestCount, this.maxRequestLimit);
           this.validedEmail(this.results[this.totalCompletedCount].email, this.totalCompletedCount);
          } else {
            console.log("delay")
            setTimeout(()=>{
              this.requestQueue()
              console.log("[delay-time]", 5000);
            }, 5000);


          }
        },
        complete:()=>{
         console.log("recursion")
        }
      })
    }
  }

  rowEditInit(car) {
  }

  onRowEditSave() {
  }

  onRowEditCancel() {
  }
  myUploader(event) {

    const reader:FileReader  = new FileReader();

    reader.readAsText(event.files[0]);

    reader.onload = (e)=>{
      this.csvToArr(reader.result);
    }
  }

	csvToArr(stringValue){
		const [keys, ...rest] = stringValue.trim().split('\n').map(item=> item.split(','));

		rest.forEach((rowData, index)=> {
      	const result = new EmailViewModel();
				result.id = rowData[0];
				result.name = rowData[1];
				result.email = rowData[2];
				result.country_code = rowData[3];
				result.phone = rowData[4];
				result.status = Status.pending;
				result.profile_image = rowData[6];
				result.created_at = rowData[7];
				const _id = result.id+result.email;

        localStorage.removeItem(_id.toString());
        Object.assign(this.dataSource,{
          [_id]: result
        });
        this.totalCount++;
    });
    this.results = Object.values(this.dataSource);
    // this.requestQueue();

    // this.validedEmailRecur();

    for (let i = 0; i < this.parallelCount; i++) {
      if(this.curr_index < this.results.length){
        this.validedEmailRecurMulti(this.curr_index);
        this.curr_index++;
      }
    }
    // this.validedEmailBulk({data:this.results.map(result => result.email)});
	}

  private validedEmailBulk(emails){

    this.pendingRequestCount = this.pendingRequestCount + 1;

    this.emailValidationService.getBulk(emails).subscribe(res=>{console.log(res)})
  }

  private validedEmailRecur(){

    this.results[this.curr_index].status = "Processing";

    this.emailValidationService.get(this.results[this.curr_index].email).pipe(delay(1000)).subscribe({
      next: res => {
        this.results[this.curr_index].status = res.status[0]
      },
      error: err => {
        throw new Error(err.message);
      },
      complete:()=>{
        this.curr_index++;
        if(this.curr_index < this.results.length)
          this.validedEmailRecur()
        console.log("done : "+this.curr_index);
      }
    })
  }
  
  private validedEmailRecurMulti(loc_index: number){

    this.results[loc_index].status = "Processing";

    this.emailValidationService.get(this.results[loc_index].email).pipe(delay(1000)).subscribe({
      next: res => {
        this.results[loc_index].status = res.status[0]
      },
      error: err => {
        throw new Error(err.message);
      },
      complete:()=>{
        this.curr_index++;
        if(this.curr_index < this.results.length)
          this.validedEmailRecurMulti(this.curr_index)
        console.log("done : "+this.curr_index);
      }
    })
  }

  private validedEmail(email: string, index: number){

    this.pendingRequestCount = this.pendingRequestCount + 1;

    this.emailValidationService.get(email).pipe(delay(1000)).subscribe({
      next: res => {
        this.results[index].status = res.status[0]
      },
      error: err => {
        throw new Error(err.message);
      },
      complete:()=>{
        this.pendingRequestCount--;
        this.totalCompletedCount++;
        this.totalCount--;

        console.log("done");
      }
    })
  }
  postFile(path,fileToUpload: File): Observable<any> {
   const headers= new HttpHeaders()
    .set('content-type', 'multipart/form-data')
    .set('Access-Control-Allow-Origin', '*');

    // let headers = new Headers();
    //headers.append('Content-Type', 'multipart/form-data');
    //headers.append('enctype', 'multipart/form-data');
    const endpoint = `${environment.api_url}${path}`;
    const formData: FormData = new FormData();
    formData.append('myfile', fileToUpload, fileToUpload.name);
    // const headers = { 'content-type': 'application/json'}
    return this.httpClient
    .post(endpoint, formData, {"headers": headers})
    .pipe(
      map((res: Response) => res.json())
    );
  }


}
