import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import {HttpClientModule} from '@angular/common/http';




import {MenuItem} from 'primeng/api';                  //api
import {FileUploadModule} from 'primeng/fileupload';


import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { HomeModule } from './home/home.module';
import {
  FooterComponent,
  HeaderComponent,
  SharedModule
} from './shared';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';


import { LandingModule } from './landing/landing.module';
import { SearchModule } from './search/search.module';
import { EmailValidationService } from './core/services/email-validation.service';
import { CsvFileUploadComponent } from './csv-file-upload/csv-file-upload.component';
import { AisearchModule } from './aisearch/aisearch.module';

@NgModule({
  declarations: [AppComponent, FooterComponent, HeaderComponent, CsvFileUploadComponent],
  imports: [
    BrowserModule,
	TableModule,
	InputTextModule,
    FileUploadModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    HomeModule,
    AuthModule,
    AppRoutingModule,
    LandingModule,
    SearchModule,
    AisearchModule
  ],
  providers: [EmailValidationService],
  bootstrap: [AppComponent]
})
export class AppModule {}
