import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Errors, UserService } from '../core';
import { EmailAisearch } from './email-aisearch.service';

import { map ,  take } from 'rxjs/operators';
import { EmailValidationService } from '../core/services/email-validation.service';

@Component({
  selector: 'app-aisearch-page',
  templateUrl: './aisearch.component.html'
})
export class AisearchComponent implements OnInit {
  authType: boolean = false;
  title: String = '';
  errors: Errors = {errors: {}};
  isSubmitting = false;
  searchForm: FormGroup;
  results: any[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private emailAisearch: EmailAisearch,
    private emailValidationService: EmailValidationService
  ) {
    // use FormBuilder to create a form group
    this.searchForm = this.fb.group({
      'fname': ['', Validators.required],
      'lname': ['', Validators.required],
      'domain': ['', Validators.required],      
      'multi': [false, Validators.required]      
    });
  }

  ngOnInit() {
    this.route.url.subscribe(data => {
      // Set a title for the page accordingly
      this.userService.isAuthenticated.pipe(take(1), map(isAuth => !isAuth)).subscribe(data => {this.authType = data});
      this.title = (this.authType) ? 'Validate' : 'Validate';
      this.results = Array();
    });
  }

  /*
    john@example.com
    jsmith@example.com
    john.smith@example.com
    smith.john@example.com
    johns@example.com
    j.smith@example.com
    john.s@example.com
  */

  next_email(fname,lname,domain,index):string {
    let email;

    switch(index){
      case 0:
        email = fname+"@"+domain;
      break;
      case 1:
        email = fname.substring(0,1)+""+lname+"@"+domain;
      break;
      case 2:
        email = fname+"."+lname+"@"+domain;
      break;
      case 3:
        email = lname+"."+fname+"@"+domain;
      break;
      case 4:
        email = fname+""+lname.substring(0,1)+"@"+domain;
      break;
      case 5:
        email = fname.substring(0,1)+"."+lname+"@"+domain;
      break;
      case 6:
        email = fname+"."+lname.substring(0,1)+"@"+domain;
      break;
      case 7:
        email = lname+"@"+domain;
      break;
      case 8:
        email = "";
      break;
      
    }
    
    return email;
  }

  recrcEmailValidator(fname,lname,domain,index=0,multi=false){
    let email = this.next_email(fname,lname,domain,index);
    if(email != "")  {
      this.emailValidationService.get(email).subscribe(
        data => {
          if(data.status == "Catchall"){
            this.results.push(data);
            console.log(this.results);
            this.isSubmitting = false;
            // this.searchForm.value.email = "";

          } else {

            if(data.status == "Valid"){
              this.results.push(data);
              console.log(this.results);
              this.isSubmitting = false;
              // this.searchForm.value.email = "";
  
              if(multi) {
                index++;
                this.recrcEmailValidator(fname,lname,domain,index,multi);
              }
  
            } else {
              index++;
              this.recrcEmailValidator(fname,lname,domain,index,multi);
            }
          }         
        }
      );
    } else {
      this.isSubmitting = false;
    }
  }

  submitForm() {
    this.isSubmitting = true;
    this.errors = {errors: {}};

    const fname = this.searchForm.value.fname;
    const lname = this.searchForm.value.lname;
    const domain = this.searchForm.value.domain;
    const multi = this.searchForm.value.multi;
    let email = this.recrcEmailValidator(fname,lname,domain,0,multi);
  }
}
