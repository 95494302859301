import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SearchComponent } from './search.component';
import { EmailSearch } from './email-search.service';
import { SharedModule } from '../shared';
import { SearchRoutingModule } from './search-routing.module';
import { EmailValidationService } from '../core/services/email-validation.service';

@NgModule({
  imports: [
    SharedModule,
    SearchRoutingModule
  ],
  exports: [SearchComponent],
  declarations: [
    SearchComponent
  ],
  providers: [
    EmailSearch,
    EmailValidationService
  ]
})
export class SearchModule {}
